import * as i from 'types';
import { ActionType, action } from 'typesafe-actions';

import { isInPast } from 'services';

const GET_SERIES = 'meSeries/GET';
const GET_SERIES_SUCCESS = 'meSeries/GET_SUCCESS';
const GET_SERIES_FAILED = 'meSeries/GET_FAILED';

const RESET = 'meSeries/RESET';

const GET_CONTRACTS = 'meSeries/GET_CONTRACTS';
const GET_CONTRACTS_SUCCESS = 'meSeries/GET_CONTRACTS_SUCCESS';
const GET_CONTRACTS_FAILED = 'meSeries/GET_CONTRACTS_FAILED';

export const meSeriesActions = {
  getMeSeries: () => action(GET_SERIES),
  getMeSeriesSuccess: (series: i.MeSerie[]) => action(GET_SERIES_SUCCESS, series),
  getMeSeriesFailed: (error: string) => action(GET_SERIES_FAILED, error),

  getMeContracts: () => action(GET_CONTRACTS),
  getMeContractsSuccess: (contracts: i.MeSerie[]) => action(GET_CONTRACTS_SUCCESS, contracts),
  getMeContractsFailed: (error: string) => action(GET_CONTRACTS_FAILED, error),

  reset: () => action(RESET),
} as const;

const initialState: i.MeSeriesState = {
  data: {
    series: [],
    contracts: [],
  },
  error: false,
  loading: false,
};

export default (state = initialState, action: ActionType<typeof meSeriesActions>): i.MeSeriesState => {
  switch (action.type) {
    case GET_CONTRACTS:
    case GET_SERIES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          contracts: action.payload,
        },
        error: false,
        loading: false,
      };
    case GET_SERIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          series: action.payload,
        },
        error: false,
        loading: false,
      };
    case GET_CONTRACTS_FAILED:
    case GET_SERIES_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const getMeSeries: i.GetMeSeries = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(meSeriesActions.getMeSeries());

    api.get({
      path: '/proxy/zingfit/account/series',
    }).then((series: { content: i.MeSerie[] }) => {
      const content = series
        ? series.content.filter((serie) => !serie.expired && !isInPast(serie.expiringDate))
        : [];

      dispatch(meSeriesActions.getMeSeriesSuccess(content));
      resolve(series.content);
    }).catch((error: string) => {
      dispatch(meSeriesActions.getMeSeriesFailed(error));
      reject();
    });
  })
);

export const getMeContracts: i.GetMeSeries = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(meSeriesActions.getMeContracts());

    api.get({
      path: '/proxy/zingfit/account/contracts',
    }).then((contracts: { content: { purchased: i.MeSerie[]; ended: boolean; }[] }) => {
      const contractSerie = contracts.content?.filter((contract) =>
        contract.ended === false)
        .map((contract) => ({
          ...contract.purchased[0],
        }));

      if (contractSerie) {
        dispatch(meSeriesActions.getMeContractsSuccess(contractSerie));
        resolve(contractSerie);
      } else {
        reject();
      }
    }).catch((error: string) => {
      dispatch(meSeriesActions.getMeContractsFailed(error));
      reject();
    });
  })
);
