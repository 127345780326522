import * as i from 'types';
import { ActionType, action } from 'typesafe-actions';

export const rewardsActions = {
  get: () => action('rewards/GET'),
  getSuccess: (rewards: i.LoyaltyReward[]) => action('rewards/GET_SUCCESS', rewards),
  getFailed: () => action('rewards/GET_FAILED'),
  getActive: () => action('rewards/GET_ACTIVE'),
  getActiveSuccess: (rewards: i.LoyaltyReward[]) => action('rewards/GET_ACTIVE_SUCCESS', rewards),
  getActiveFailed: () => action('rewards/GET_ACTIVE_FAILED'),
  reset: () => action('rewards/RESET'),
} as const;
const initialState: i.RewardsState = {
  data: [],
  active: [],
  error: false,
  loading: false,
};

export default (state = initialState, action: ActionType<typeof rewardsActions>): i.RewardsState => {
  switch (action.type) {
    case 'rewards/GET_ACTIVE':
    case 'rewards/GET':
      return {
        ...state,
        error: false,
        loading: true,
      };
    case 'rewards/GET_SUCCESS':
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false,
      };
    case 'rewards/GET_ACTIVE_SUCCESS':
      return {
        ...state,
        active: action.payload,
        error: false,
        loading: false,
      };
    case 'rewards/GET_ACTIVE_FAILED':
    case 'rewards/GET_FAILED':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case 'rewards/RESET':
      return initialState;
    default:
      return state;
  }
};

export const getRewards: i.GetRewards = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(rewardsActions.get());
    api.get({
      path: '/proxy/zingfit/account/rewards',
    }).then((rewards: i.LoyaltyReward[]) => {
      dispatch(rewardsActions.getSuccess(rewards));
      resolve(rewards);
    }).catch(() => {
      dispatch(rewardsActions.getFailed());
      reject();
    });
  })
);

export const getActiveRewards: i.GetRewards = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(rewardsActions.getActive());
    api.get({
      path: '/proxy/zingfit/account/rewards/active',
    }).then((rewards: i.LoyaltyReward[]) => {
      dispatch(rewardsActions.getActiveSuccess(rewards));
      resolve(rewards);
    }).catch(() => {
      dispatch(rewardsActions.getActiveFailed());
      reject();
    });
  })
);
