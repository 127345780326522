import 'styled-components';

const theme = {
  colors: {
    primary: Object.assign('#FF6761', {
      medium: '#FF6761',
      dark: '#FF6761',
    }),
    secondary: '#C8CBBF',
    black: '#000000',
    gray: Object.assign('#B1B0B1', {
      light: '#F1F2F2',
      medium: '#B1B0B1',
      dark: '#5B6770',
      background: '#F7F7F5',
    }),
    white: Object.assign('#FFFFFF', {
      opacity: 'rgba(255, 255, 255, .4)',
      off: 'rgba(255, 255, 255, .1)',
    }),
    green: {
      '500': '#A1D664',
      '700': '#78A545',
    },
    lightRed: '#F85F5F',
    darkRed: '#9E0001',
    red: '#9E0001',
    orange: '#FFAF23',
    special: '#A1D664',
  },
  fonts: {
    primary: 'AcuminPro-regular, sans-serif',
  },
} as const;

export default theme;
