import * as i from 'types';

export const generateTypeTarget = (type: i.SysTypeTargets) => {
  switch (type) {
    case 'concept':
      return '/concepts';
    case 'trainer':
      return '/instructors';
    case 'studio':
      return '/studios';
    case 'pageLayout':
    case 'websiteAlternativePages':
      return '';
    default:
      return '/';
  }
};

export const getSlug = (type?: i.SysTypeTargets, slug?: string) => {
  const generatedType = type ? generateTypeTarget(type) : '/';
  const generatedSlug = slug ? (slug === '/' ? null : `/${slug}`) : '';

  return `${generatedType}${generatedSlug}`;
};
