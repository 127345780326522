import * as i from 'types';
import { isBrowser } from 'services';

export const triggerGtagEvent = (
  eventName: EventName,
  eventData: EventData,
) => {
  if (isBrowser() && window.gtag) {
    window.gtag('event', eventName, {
      ...eventData,
    });
  }
};

type EventName = 'sign_up'
| 'add_to_cart'
| 'begin_checkout'
| 'purchase';

type EventData = SignupData
| i.Serie
| BeginCheckoutData
| PaymentData;

type SignupData = {
  method: 'website',
};

type BeginCheckoutData = {
  user: i.User;
  serie: i.Serie;
  payment_method: i.PaymentMethods;
};

type PaymentData = {
  orderId: string;
  order: i.OrderComplete;
};
