import * as i from 'types';
import 'isomorphic-fetch';
import qs from 'qs';

import { getAuthenticationConfig } from './config';

// Save access token in memory.
// This needs to be done outside of react, because we have to access the token in our middleware.
let token: i.AuthenticationTokenType = undefined;

export const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

export const getRefreshToken = (): string | null =>  {
  const { TOKEN_STORAGE_KEY } = getAuthenticationConfig();
  return localStorage.getItem(TOKEN_STORAGE_KEY);
};

const setAccessToken = (newToken: string, expires?: number) => {
  const { REFRESH_TOKEN_INTERVAL } = getAuthenticationConfig();

  // Expiry comes from Zingfit's OAuth2 implementation in seconds. For safety reasons
  // we enable a window of 5 seconds so calls won't collapse. If no expiry interval
  // is set for some reason, the expiry becomes a default of 5 minutes
  const expiry = getCurrentTimestamp() + (expires ? expires - 5 : REFRESH_TOKEN_INTERVAL);

  token = {
    token: newToken,
    expires_at: expiry,
  };
};

export const setAuthenticationTokens = ({
  refresh, access, expires,
}: i.AuthenticationTokensPayload) =>  {
  const { TOKEN_STORAGE_KEY } = getAuthenticationConfig();
  localStorage.setItem(TOKEN_STORAGE_KEY, refresh);
  if (access) setAccessToken(access, expires);
};

export const removeAuthenticationTokens = () => {
  const { TOKEN_STORAGE_KEY } = getAuthenticationConfig();
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  token = undefined;
};

export const getAuthenticationToken = () => new Promise<string>(
  async (resolve, reject) => {
    const refresh = getRefreshToken();

    if (refresh) {
      const current = getCurrentTimestamp();

      // Check if refresh needs refreshing by comparing timestamps
      if (!token || current >= token.expires_at) {

        try {
          const newToken = await Auth.post({
            path: '/proxy/zingfit/oauth/refresh-token',
            body: {
              refresh_token: refresh,
            },
          });

          setAccessToken(`${newToken.token_type} ${newToken.access_token}`, newToken.expires_in);
        } catch {
          removeAuthenticationTokens();
        }
      }
    }

    return token
      ? resolve(token.token)
      : reject();
  },
);

const formatAuthApiOptions = (options: i.AuthFetchOptions, method: string): i.AuthRequestOptions => {
  const { path, query, body } = options;

  return {
    path: `${process.env.GATSBY_API_URL}${path}${query ? `?${qs.stringify(query, { encode: false })}` : ''}`,
    options: {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      ...(body && {
        body: JSON.stringify(body),
      }),
    },
  };
};

export const authRequest: i.AuthRequest = ({
  path, options,
}) => new Promise(async (resolve, reject) => {
  fetch(path, options)
    .then(async (response) => {
      if (response.ok) {
        return response.status !== 204
          ? response.json()
          : response.text();
      }

      return Promise.reject(response.json());
    })
    .then((json) => { resolve(json); })
    .catch((json) => {
      try {
        json.then((err: i.ApiError) => {
          reject(err);
        }).catch((err: i.ApiError) => {
          reject(err);
        });
      } catch (err) {
        reject(json);
      }
    });
});

export const Auth = {
  get: (options: i.AuthFetchOptions) => authRequest(formatAuthApiOptions(options, 'GET')),
  del: (options: i.AuthFetchOptions) => authRequest(formatAuthApiOptions(options, 'DELETE')),
  post: (options: i.AuthFetchOptions) => authRequest(formatAuthApiOptions(options, 'POST')),
  put: (options: i.AuthFetchOptions) => authRequest(formatAuthApiOptions(options, 'PUT')),
  patch: (options: i.AuthFetchOptions) => authRequest(formatAuthApiOptions(options, 'PATCH')),
};
